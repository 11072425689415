import { UploadOutlined } from '@ant-design/icons';
import { message, Row, Space, Spin, Table, Upload } from 'antd';
import colors from 'constants/colors';
import {
  IS_BASAO,
  IS_HIGHUP,
  IS_LG,
  IS_LX,
  IS_MENARD,
  IS_TRONGDONG,
  IS_VIMARU,
  MODULE_ID,
  studentPronouns1,
} from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import editUser from 'modules/Administration/api/editUser';
import registerNewUser from 'modules/Administration/api/registerNewUser';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import React, { useMemo, useState } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { getData } from '../ManageKhoaNganh/components/InsertData';
import { columns, columnsLG, columnsMenard, columnsVimaru } from './columns';
import moment from 'moment';
import { formatDate, formatDateQuery } from 'constants/FormatString';

const fetcherAllClass = (userId) => () => {
  return query('p_VIMARU_CLASSES_GetClasses', { userId });
};

message.config({
  maxCount: 1,
});

const ExcelRegister = ({ buttonCloseModal, dataShop, dataPosition }) => {
  const [rows, setRows] = useState([]);
  const [isInformationTeacher, setInformationTeacher] = useState();
  const [loading, setLoading] = useState(false);
  const [disableButtonAdd, setDisableButtonAdd] = useState(true);
  const [loadingUploadFile, seLoadingUploadFile] = useState(false);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const queryClient = useQueryClient();
  const key = ['p_VIMARU_CLASSES_GetClasses'];

  const { data: dataAllClass, isLoading } = useQuery(key, fetcherAllClass(loggedInUser?.id));
  const { data: onGetDataCrud } = getData();

  const tableKey = useMemo(() => {
    const stringArr = rows.map((row) => row.status);
    return stringArr.toString();
  }, [rows]);

  const displayColumns =
    IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX
      ? columnsMenard
      : IS_HIGHUP || IS_VIMARU
      ? columnsVimaru
      : IS_LG
      ? columnsLG
      : columns;

  const templateHref = IS_VIMARU
    ? '/templates/excel/TemplateUsers_VIMARU.xlsx'
    : `https://cloud.softech.vn/storage/Data/TemplateImportExel/${
        IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX ? 'Menard' : IS_LG ? 'LG' : ''
      }TemplateRegister.xlsx`;

  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  const mailer =
    ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value ?? 'AMES ENGLISH <mailer@ames.edu.vn>';
  const templateRegisterDefault =
    ConfigSettings?.find((x) => x?.key.toUpperCase() === 'TEMPLATE_REGISTER')?.value ?? 'VIMARU_Mail_RegisterAccount';

  const sendEmailRegister = ({ to, templateName, templateData }) =>
    new Promise(async (resolve, reject) => {
      const dataSendMail = {
        to,
        isSendFast: true,
        moduleId: MODULE_ID,
        cc: '',
        bcc: '',
        subject: 'THÔNG BÁO TÀI KHOẢN - HỌC PHẦN AVCB',
        body: '',
        bodyHTML: '',
        attachment: '',
        templateName,
        sourceInfo: `API Save Email - ${templateName}`,
        templateData: JSON.stringify(templateData),
        mailer,
      };
      const res = await amesSendMail('saveEmail', dataSendMail);

      if (res.Message === 'OK') {
        return resolve(1);
      } else {
        return reject(0);
      }
    });

  const testSendMail = async (data) => {
    //Chỉ riêng site Vimaru mới gửi mail phía FE, còn lại thì gửi mail phía BE
    const dateNo = moment().format('YYYYMMDDhhmm');
    const birthdayText = data?.birthDay ? moment(data?.birthDay, formatDateQuery).format(formatDate) : '';
    const templateMail =
      data?.templateMail === 'AVCB1'
        ? 'VIMARU_Mail_RegisterAccount_AVCB1'
        : data?.templateMail === 'AVCB2'
        ? 'VIMARU_Mail_RegisterAccount_AVCB2'
        : templateRegisterDefault;
    const templateData = {
      dateNo: dateNo,
      studentName: data?.fullname,
      birthday: birthdayText,
      studentCode: data?.maSV,
      khoaNganh: data?.dataKhoa?.categoryName ?? '',
      phoneNumber: data?.phone ?? '',
      email: data?.email,
      password: data?.password,
      class: data?.dataClass?.className ?? '',
    };
    const resMail = await sendEmailRegister({ to: data?.email, templateName: templateMail, templateData });
    console.log('🚀 ~ testSendMail ~ resMail:', resMail);
    if (!resMail) {
      console.log('Tạo tài khoản thành công, gửi mail thất bại');
    }
  };

  const saveAccount = (data) =>
    new Promise(async (resolve) => {
      const params = {
        email: data?.email,
        password: data?.password,
        passwordEncrypt: generateSHA1(data?.password),
        fullName: data?.fullname,
        roleId: data?.userType,
        cityId: 0,
        phone: data?.phone,
        maSv: data?.maSV,
        note: data?.note,
        teacherId: data?.teacherId,
        khoa: data?.dataKhoa?.id || '00000000-0000-0000-0000-000000000000',
        nganh: data?.dataChuyenNganh?.id || '00000000-0000-0000-0000-000000000000',
        classId: data?.dataClass?.id,
        birthDay: data?.birthDay,
        status: data?.activeCode,
        sex: data?.sex,
        shop: data?.dataShop?.id || '00000000-0000-0000-0000-000000000000',
        chucVu: data?.dataPosition?.id || '00000000-0000-0000-0000-000000000000',
        ngayVaoCongTy: data?.ngayVaoCongTy,
      };
      const res = await registerNewUser(params);

      if (res?.[0].res === 1) {
        if (IS_VIMARU) {
          //Chỉ riêng site Vimaru mới gửi mail phía FE, còn lại thì gửi mail phía BE
          const dateNo = moment().format('YYYYMMDDhhmm');
          const birthdayText = data?.birthDay ? moment(data?.birthDay, formatDateQuery).format(formatDate) : '';
          const templateMail =
            data?.templateMail === 'AVCB1'
              ? 'VIMARU_Mail_RegisterAccount_AVCB1'
              : data?.templateMail === 'AVCB2'
              ? 'VIMARU_Mail_RegisterAccount_AVCB2'
              : templateRegisterDefault;
          const templateData = {
            dateNo: dateNo,
            studentName: data?.fullname,
            birthday: birthdayText,
            studentCode: data?.maSV,
            khoaNganh: data?.dataKhoa?.categoryName ?? '',
            phoneNumber: data?.phone ?? '',
            email: data?.email,
            password: data?.password,
            class: data?.dataClass?.className ?? '',
          };
          const resMail = await sendEmailRegister({ to: data?.email, templateName: templateMail, templateData });
          if (!resMail) {
            //Gửi mail thất bại
            return resolve({
              ...data,
              color: 'red',
              status: 'Tạo tài khoản thành công, gửi mail thất bại',
            });
          }
        }
        //Tạo user, gửi mail thành công
        return resolve({
          ...data,
          status: 'Tạo mới thành công',
        });
      }
      //Tạo user thất bại
      const errorMessage =
        res?.[0].email === data?.email
          ? 'Email đã tồn tại'
          : res?.[0].maSV === data?.maSV
          ? 'Mã sinh viên đã tồn tại'
          : 'Tạo mới thất bại';

      return resolve({
        ...data,
        color: 'red',
        status: errorMessage,
      });
    });
  const updateAccount = (data) =>
    new Promise(async (resolve) => {
      const {
        userId,
        maSV,
        email,
        fullname,
        phone,
        userType,
        sex,
        activeCode,
        birthDay,
        dataClass,
        dataKhoa,
        dataChuyenNganh,
        dataShop,
        dataPosition,
        ngayVaoCongTy,
      } = data;
      const res = await editUser({
        id: userId,
        maSV: maSV,
        email,
        name: fullname,
        phone,
        status: activeCode,
        roleId: userType,
        sex,
        avatar: '', // excel không có cột update avatar
        birthDay: birthDay ?? null,
        // schoolTypeId,
        classId: dataClass?.id ?? null,
        khoa: dataKhoa?.id || '00000000-0000-0000-0000-000000000000',
        nganh: dataChuyenNganh?.id || '00000000-0000-0000-0000-000000000000',
        chucVu: dataPosition?.id || '00000000-0000-0000-0000-000000000000',
        shop: dataShop?.id || '00000000-0000-0000-0000-000000000000',
        ngayVaoCongTy: ngayVaoCongTy ?? null,
      });
      if (res?.[0]?.res === 1) {
        if (IS_VIMARU && ['AVCB1', 'AVCB2'].includes(data?.templateMail)) {
          //Chỉ riêng site Vimaru mới gửi mail phía FE, còn lại thì gửi mail phía BE
          //Phía VIMARU muốn gửi lại mail chào mừng khi học sinh vào khoá học AVCB1 hoặc AVCB2
          const dateNo = moment().format('YYYYMMDDhhmm');
          const birthdayText = data?.birthDay ? moment(data?.birthDay, formatDateQuery).format(formatDate) : '';
          const templateMail =
            data?.templateMail === 'AVCB1'
              ? 'VIMARU_Mail_RegisterAccount_AVCB1'
              : data?.templateMail === 'AVCB2'
              ? 'VIMARU_Mail_RegisterAccount_AVCB2'
              : '';
          const templateData = {
            dateNo: dateNo,
            studentName: data?.fullname,
            birthday: birthdayText,
            studentCode: data?.maSV,
            khoaNganh: data?.dataKhoa?.categoryName ?? '',
            phoneNumber: data?.phone ?? '',
            email: data?.email,
            password: '',
            class: data?.dataClass?.className ?? '',
          };
          const resMail = await sendEmailRegister({ to: data?.email, templateName: templateMail, templateData });
          if (!resMail) {
            //Gửi mail thất bại
            return resolve({
              ...data,
              color: 'red',
              status: 'Tạo tài khoản thành công, gửi mail thất bại',
            });
          }
        }
        return resolve({
          ...data,
          color: 'green',
          status: 'Cập nhật thành công',
        });
      } else {
        return resolve({
          ...data,
          color: 'red',
          status: 'Cập nhật thất bại!',
        });
      }
    });
  const getDataTeacher = async (teacherId) => {
    const parameter = {
      userId: Number(teacherId),
      email: '',
      name: '',
      phone: '',
      maSV: '',
      roleId: 2,
      status: 'ACTIVE',
    };
    const dataTeacher = await query('p_VIMARU_USERS_Search', parameter);
    return {
      name: dataTeacher?.[0]?.fullName,
      phone: dataTeacher?.[0]?.phone,
      email: dataTeacher?.[0]?.email,
    };
  };
  const checkClass = (classId) => {
    if (!classId) {
      return {
        status: true,
        teacherId: null,
        dataClass: [],
        dataTeacher: [],
      };
    }
    const filterDataClass = dataAllClass?.filter((item) => {
      return item.code === classId;
    });
    if (filterDataClass?.length === 0) return { status: false, teacherId: null, dataClass: [], dataTeacher: [] };
    return {
      status: true,
      teacherId: filterDataClass?.[0]?.teacherId,
      dataClass: filterDataClass?.[0],
      dataTeacher: isInformationTeacher,
    };
  };

  const checkKhoa = (codeKhoa) => {
    if (!codeKhoa) return { status: true, data: [] };
    const filterDataKhoa = onGetDataCrud.filter((item) => {
      return item.code.toUpperCase() === codeKhoa.toUpperCase();
    });
    if (filterDataKhoa.length === 0) return { status: false, data: [] };
    return {
      status: true,
      data: filterDataKhoa?.[0],
    };
  };
  const checkChuyenNganh = (codeChuyenNganh) => {
    if (!codeChuyenNganh) return { status: true, data: [] };
    const filterDataKhoa = onGetDataCrud.filter((item) => {
      return item.code.toUpperCase() === codeChuyenNganh.toUpperCase();
    });
    if (filterDataKhoa.length === 0) return { status: false, data: [] };
    return {
      status: true,
      data: filterDataKhoa?.[0],
    };
  };
  const checkShop = (shop) => {
    // Không nhập mã bộ phận thì không cần check
    if (!shop) return { status: true, data: {}, message: 'Hợp lệ' };
    else {
      // Có mã bộ phận
      const shopData = dataShop?.find((item) => item?.code.toUpperCase() === shop.toUpperCase());
      if (!shopData)
        return { status: false, data: {}, message: i18next.t('Code does not exit', { field: i18next.t('Part') }) };
      else {
        // kiểm tra xem bộ phận có hợp lệ không, vì bộ phận menard có nhiều cấp, chỉ chấp nhận bộ phận là cấp cuối cùng của cây bộ phận
        if (dataShop?.some((item) => item?.parentId === shopData?.id))
          return { status: false, data: shopData, message: 'Cấp bộ phận không hợp lệ' };
        return {
          status: true,
          data: shopData,
          message: 'Hợp lệ',
        };
      }
    }
  };
  const checkPosition = (position) => {
    if (!position) return { status: true, data: [] };
    const filterDataKhoa = dataPosition?.filter((item) => {
      return item.code.toUpperCase() === position.toUpperCase();
    });
    if (filterDataKhoa?.length === 0) return { status: false, data: [] };
    return {
      status: true,
      data: filterDataKhoa?.[0],
    };
  };

  // chuyển đổi "Học sinh"  => "HOCSINH"
  const convertText = (text) => {
    return text
      ?.toString()
      ?.split(' ')
      .join('')
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D');
  };

  // Nếu text có định dạng email thì trả về true, ngược lại thì false
  const isEmail = (email) => {
    // Regular expression to check for a valid email address
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // Return true if the email address is valid, false otherwise
    return regex.test(email?.replace(/ /g, ''));
  };

  // Check xem người dùng có nhập đúng role không, nếu sai thì trả về -1
  const checkRole = (role) => {
    const roleId =
      role === 'SINHVIEN' || role === 'HOCSINH' || role === 'HOCVIEN' || role === 'STUDENT'
        ? 1
        : role === 'GIAOVIEN' || role === 'TEACHER'
        ? 2
        : (role === 'QUANTRIVIEN' || role === 'MANAGER') && loggedInUser.role === 'administrator'
        ? 3
        : -1;
    return roleId;
  };

  // Nếu text có định dạng email thì trả về true, ngược lại thì false
  const checkGender = (text) => {
    const formatText = convertText(text);
    const genderId = ['NU', 'FEMALE'].some((i) => i === formatText)
      ? 0
      : ['NAM', 'MALE'].some((i) => i === formatText)
      ? 1
      : -1;
    return genderId;
  };
  // check dữ liệu từ excel và match categoryCode
  const handleRowsData = async (data) => {
    //Loại bỏ các dòng trống hoặc không có email (row[2])
    const removedEmptyRows = await data.filter((row, index) => !!row?.[2]?.trim());
    if (removedEmptyRows?.length == 0) {
      return [];
    }
    const newRows = await Promise.all(
      removedEmptyRows.map(
        (row, index) =>
          new Promise(async (resolve) => {
            //Móc dữ liệu excel theo tên để dễ nhận biết field
            let userInfor =
              IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX
                ? {
                    maSV: row[0],
                    fullname: row[1],
                    email: row[2],
                    phone: row[3],
                    code_class: row[4],
                    code_shop: row[5],
                    code_position: row[6],
                    note: row[7],
                    roleName: row[8],
                    gender: row[9],
                    birthDay: row[10],
                    activeCode: row[11],
                    ngayVaoCongTy: row[12],
                  }
                : IS_VIMARU || IS_HIGHUP
                ? {
                    maSV: row[0],
                    fullname: row[1],
                    email: row[2],
                    phone: row[3],
                    code_class: row[4],
                    code_khoa: row[5],
                    code_chuyeNganh: row[6],
                    note: row[7],
                    roleName: row[8],
                    gender: row[9],
                    birthDay: row[10],
                    activeCode: row[11],
                    templateMail: row[12],
                  }
                : IS_LG
                ? {
                    maSV: row[0],
                    fullname: row[1],
                    email: row[2],
                    phone: row[3],
                    code_class: row[4],
                    code_shop: row[5],
                    note: row[6],
                    roleName: row[7],
                    gender: row[8],
                    birthDay: row[9],
                    activeCode: row[10],
                  }
                : {
                    maSV: row[0],
                    fullname: row[1],
                    email: row[2],
                    phone: row[3],
                    code_class: row[4],
                    note: row[7],
                    roleName: row[8],
                    gender: row[9],
                    birthDay: row[10],
                    activeCode: row[11],
                  };
            // Loại bỏ khoảng trắng thừa và định dạng lại dữ liệu từ excel
            userInfor = {
              ...userInfor,
              index,
              maSV: userInfor.maSV?.toString()?.trim(),
              fullname: userInfor.fullname?.toString()?.trim(),
              email: userInfor.email?.toString()?.replace(/ /g, '')?.toLowerCase(),
              phone: userInfor.phone?.toString()?.trim() ?? '',
              code_class: userInfor.code_class?.toString()?.trim() ?? '',
              note: userInfor.note?.toString()?.trim() ?? '',
              roleName: userInfor.roleName?.toString()?.trim(),
              gender: userInfor.gender?.toString()?.trim() ?? '',
              birthDay: userInfor.birthDay?.toString()?.replaceAll('/', '-')?.trim() ?? '',
              activeCode: userInfor.activeCode?.toString()?.trim() ?? 'ACTIVE',
              code_shop: userInfor.code_shop?.toString()?.trim(), // LG & Menard
              code_position: userInfor.code_position?.toString()?.trim(), // Menard
              ngayVaoCongTy: userInfor.ngayVaoCongTy?.toString()?.trim() ?? '', // Menard
              code_khoa: userInfor.code_khoa?.toString()?.trim(), //Vimaru & HighUp
              code_chuyeNganh: userInfor.code_chuyeNganh?.toString()?.trim(), //Vimaru & HighUp
            };
            const {
              maSV,
              fullname,
              email,
              code_class,
              roleName, // học sinh / student /...
              gender,
              code_shop, //  Menard & LG
              code_position, // riêng Menard
              code_khoa, // Vimanru & Highup
              code_chuyeNganh, // Vimanru & Highup
            } = userInfor;
            userInfor.sex = checkGender(gender);
            // Check xem đã nhập đủ các field bắt buộc chưa ?
            const requiredFields = [maSV, fullname, email, roleName].every(
              (value) => value?.replace(/\s/g, '')?.length > 0, //ô dữ liệu để trống hoặc chỉ nhập khoảng trắng là sai
            );

            // ========= CHECK DATA ================
            if (!requiredFields) {
              return resolve({
                ...userInfor,
                status: i18next.t('Please enter all required fields'),
                color: 'red',
              });
            }
            const role = convertText(roleName);
            if (checkRole(role) === -1) {
              const accessRole = `${studentPronouns1}/ ${i18next.t('teacher')}${
                loggedInUser.role === 'administrator' ? `/ ${i18next.t('Manager')}` : ''
              }`;
              return resolve({
                ...userInfor,
                status: `${i18next.t('Account type receive only')} ${accessRole}`,
                color: 'red',
              });
            }
            const roleId = checkRole(role);
            userInfor = {
              ...userInfor,
              type: roleName,
              userType: roleId, // loại tài khoản
            };
            if (!isEmail(email)) {
              return resolve({
                ...userInfor,
                status: i18next.t('Please enter correct email format'),
                color: 'red',
              });
            }
            const { status: statusClass, teacherId, dataClass, teacherData } = checkClass(code_class);
            if (!statusClass) {
              return resolve({
                ...userInfor,
                status: i18next.t('Code does not exit', { field: i18next.t('Class') }),
                color: 'red',
              });
            }
            userInfor = {
              ...userInfor,
              teacherId,
              dataClass,
              teacherData,
            };
            if (IS_VIMARU || IS_HIGHUP) {
              const { status: statusKhoa, data: dataKhoa } = checkKhoa(code_khoa);
              if (!statusKhoa) {
                return resolve({
                  ...userInfor,
                  status: 'Mã khoa không tồn tại',
                  color: 'red',
                });
              }
              userInfor.dataKhoa = dataKhoa;
              const { status: statusChuyenNganh, data: dataChuyenNganh } = checkChuyenNganh(code_chuyeNganh);
              if (!statusChuyenNganh) {
                return resolve({
                  ...userInfor,
                  status: 'Mã chuyên ngành không tồn tại',
                  color: 'red',
                });
              }
              userInfor.dataChuyenNganh = dataChuyenNganh;
            }
            if (IS_LG || IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) {
              const { status: statusShop, data: dataShop, message: messageShop } = checkShop(code_shop);
              if (!statusShop) {
                return resolve({
                  ...userInfor,
                  status: messageShop,
                  color: 'red',
                });
              }
              userInfor.dataShop = dataShop;
            }
            if (IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) {
              const { status: statusPosition, data: dataPosition } = checkPosition(code_position);
              if (!statusPosition) {
                return resolve({
                  ...userInfor,
                  status: 'Mã chức vụ không tồn tại',
                  color: 'red',
                });
              }
              userInfor.dataShop = dataPosition;
            }
            query('p_QUIZCITY_User_Email_CheckExists', { email })
              .then((res) => {
                if (res?.[0]?.userId) {
                  userInfor.userId = res?.[0]?.userId;
                  userInfor.status = i18next.t('Update');
                } else {
                  userInfor.status = i18next.t('Add');
                }
                const password = IS_MENARD ? '123456' : IS_LG ? maSV : GenPasswordModel(2, 8);
                return resolve({
                  ...userInfor,
                  password,
                  //======== 4 thuộc tính cuối để value mặc định để query check mail sau ====
                  color: 'green', // màu hiển của statusData , đúng định dạng data thì 'xanh' , ngược lại thì 'đỏ'
                });
              })
              .catch((err) => {
                return resolve({
                  ...userInfor,
                  status: 'Kiểm tra email thêm mới / update thất bại!',
                  color: 'red', // màu hiển của statusData , đúng định dạng data thì 'xanh' , ngược lại thì 'đỏ'
                });
              });
          }),
      ),
    );
    return newRows;
  };

  const fileHandler = (fileList) => {
    seLoadingUploadFile(true);
    setRows([]);
    const fileObj = fileList;
    if (!fileObj) {
      message.error(i18next.t('noFileUpload'));
      seLoadingUploadFile(false);
      return false;
    }
    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        seLoadingUploadFile(false);
      } else {
        const newRows = await handleRowsData(resp.rows.slice(1)); // mới - bỏ row 1 vì đây là tiêu đề
        if (!(newRows?.length > 0)) {
          message.error(i18next.t('nodata'));
        }
        setRows(newRows);
        seLoadingUploadFile(false);
        setDisableButtonAdd(false);
      }
    });
    return false;
  };

  const handleSubmit = () => {
    setLoading(true);

    const promises = rows.map((row) => {
      if (row.color === 'green') {
        //Check có userId theo email thì update
        if (row.userId) {
          return updateAccount(row);
        } else {
          return saveAccount(row);
        }
      } else {
        return Promise.resolve(row);
      }
    });
    Promise.all(promises)
      .then((results) => {
        message.success('Hoàn tất xử lý lưu dữ liệu!');
        setDisableButtonAdd(true);
        setRows(results);
        setLoading(false);
        queryClient.invalidateQueries('Users');
      })
      .catch((err) => {
        message.error('Có lỗi xảy ra trong quá trình lưu dữ liệu!');
        setLoading(false);
      });
  };
  //! Xóa dữ liệu trong state khi click remove file
  const handleRemoveFile = () => {
    setRows([]);
  };
  return (
    <React.Fragment>
      <Spin spinning={loadingUploadFile}>
        <div style={{ display: 'flex', alignItems: 'stretch', columnGap: 10, marginBottom: '1rem' }}>
          <Upload accept='.xlsx' name='file' beforeUpload={fileHandler} onRemove={handleRemoveFile} maxCount={1}>
            <DynamicButton icon={<UploadOutlined />}>{i18next.t('uploadExcel')}</DynamicButton>
          </Upload>
          {/* <ExportCSV csvData={data} fileName='DemoExcelFile' /> */}
          <span style={{ fontSize: 14, textDecoration: 'none', color: 'blue', cursor: 'pointer', marginTop: 4 }}>
            <a href={templateHref} download style={{ color: colors.theme.button }}>
              {i18next.t('Sample file')}
            </a>
          </span>
        </div>
        {rows?.length > 0 && !loadingUploadFile ? (
          <>
            <Spin spinning={loading} tip='Đang tạo tài khoản...'>
              <div style={{ marginTop: 20 }}>
                <Table
                  // Tạo key cho Table như này để table render lại khi biến trong key thay đổi
                  key={tableKey}
                  rowKey='maSV'
                  dataSource={rows}
                  columns={displayColumns}
                  scroll={{ x: 1500 , y: 'calc( 100vh - 360px )'}}
                />
              </div>
            </Spin>
            <Row justify='end'>
              <Space>
                {/* <DynamicButton
                  onClick={() => {
                    testSendMail(rows[0]);
                  }}
                >
                  Gửi mail test
                </DynamicButton> */}
                <DynamicButton loading={loading} onClick={handleSubmit} type='primary' disabled={disableButtonAdd}>
                  {i18next.t('Save')}
                </DynamicButton>
                <DynamicButton
                  onClick={() => {
                    buttonCloseModal();
                  }}
                >
                  {i18next.t('Close')}
                </DynamicButton>
              </Space>
            </Row>
          </>
        ) : null}
      </Spin>
    </React.Fragment>
  );
};

export default ExcelRegister;
